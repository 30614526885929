<template>
  <v-app>
    <div>

      <BreadcrumbComponent :items=items></BreadcrumbComponent>

      <PreloaderComponent :enable=enable></PreloaderComponent>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px">
          
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ form_title }}</span>
            </v-card-title>
            <v-card-text>
              <v-row style="margin-top:18px">
                <v-col cols="12" sm="12" md="12">
                  <input label="Nom *" required v-model=category.name v-on:keyup="validateName()" placeholder="Nom *"
                    class="form-control"
                    :style="validate_name ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                  <small v-if="!validate_name" style="color:#ff7370;font-style:italic">{{ validate_name_error_message
                    }}</small>

                </v-col>
              </v-row>
              <p
                style="padding:10px;margin-top:0px;border-radius:3px;background:#d9edf7; border:1px solid #c7e2f0;text-align:left;color:black;margin-top:15px">
                Les catégories servent à classer vos produits par type. Vos vendeurs seront mieux capable de localiser
                ceux-ci sur
                votre appareil android, et ainsi effectuer les ventes de manière plus rapide.</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="close_modal_button" text @click="close">
                Fermer
              </v-btn>
              <v-btn color="blue" text class="confirm_modal_button" v-on:click=save :loading="loading"
                :disabled="loading" @click="loader = 'loading'">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-container fluid class="settingscontainer" v-if=enable>
        <v-card elevation="2" class="text-right">
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher une Catégorie" single-line
                hide-details class="tablesearch"></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="rows" :items-per-page="10" class="elevation-1" :search="search">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 edit_button" @click="edit(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-container>
      
    </div>
  </v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'CategoriesView',
  methods: {
    load: function () {
      var self = this;
      this.$axios.get('/categories?orderasc=name')
        .then(function (response) {
          self.rows = response.data
          self.enable = true
        })
    },
    save: function () {
      var self = this
      if (this.validateName()) {
        this.loading = true
        var url = ''
        if (this.index > -1) {
          url = '/categories/' + this.category.id
          this.$axios.put(url + "?data=" + encodeURIComponent(JSON.stringify(self.category)))
            .then(response => {
              Object.assign(self.rows[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            })
        } else {
          url = '/categories'

          this.$axios.post(url + "?data=" + encodeURIComponent(JSON.stringify(self.category)))
            .then(response => {
              self.rows.push(response.data);
              self.dialog = false
              self.loading = false
              self.reset()
            }).catch(function (error) {
              console.log(error)
            })
        }
      }

    },
    validateName: function () {
      if (this.category.name == '') {
        this.validate_name = false
        this.validate_name_error_message = "Le nom ne peut pas être vide."
        return false
      } else {
        this.validate_name = true
        return true
      }
    },
    edit: function (item) {
      this.index = this.rows.indexOf(item)
      this.category = Object.assign({}, item)
      this.form_title = "Editer Catégorie : " + this.category.name
      this.dialog = true
    },
    close: function () {
      this.dialog = false
      this.reset()
      this.loading = false
    },
    reset: function () {
      this.category = {
        name: ''
      }
      this.index = -1
      this.validate_name = true
      this.form_title = 'Nouvelle Catégorie'
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    },
  },
  mounted() {
    this.load()
  },
  data: () => ({
    search: '',
    index: -1,
    loading: false,
    validate_name: true,
    validate_name_error_message: 'Le nom ne peut pas être vide.',
    form_title: "Nouvelle Catégorie",
    items: [
      {
        text: 'Catégories',
        disabled: false,
        href: '/categories',
      }
    ],
    fluid: true,
    enable: false,
    headers: [
      {
        text: 'NOM',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'ACTIONS', value: 'actions' },
    ],
    rows: [],
    roles: [],
    dialog: '',
    dialogDelete: false,
    category: {
      name: '',
    }
  }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
.text-right {
  text-align: right;
}

.v-application--wrap .theme--dark.v-btn.v-btn--has-bg {
  background-color: #F68D3A !important;
}

.settingscontainer {
  margin-top: 25px !important;
}

.v-application a {
  color: black !important;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

th.text-start,
td.text-start {
  text-align: center !important;
}

th.text-start:first-child,
td.text-start:first-child {
  text-align: left !important;
}

th.text-start:last-child,
td.text-start:last-child {
  text-align: right !important;
}

.class_green {
  background: #4caf50 !important;
  color: white !important;
}

.class_red,
.confirm_delete_button {
  background: #ff5252 !important;
  color: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.v-dialog button.v-btn {
  margin-right: 8px !important;
}

.text-center {
  text-align: center;
}

.tablesearch .v-input__slot:before {
  display: none;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.edit_button {
  font-size: 16px;
  padding: 5px;
  background: #003f74 !important;
  color: white !important;
  border-radius: 4px;
}

.delete_button {
  background: #ff5252 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.v-application .justify-center {
  justify-content: right !important;
}

.v-application .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 18px;
}

.v-application .v-dialog .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 0px !important;
}

.v-dialog .v-card__title {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
}

.v-btn.close_modal_button {
  background-color: #1976d2 !important;
  color: white !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
}

.v-dialog .v-card__actions {
  padding-bottom: 15px !important;
  padding-top: 20px !important;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
}

.color-red {
  color: red !important;
}

c .has_errors {
  padding-top: 0px;
  margin-top: 0px;
}

.v-card__actions .v-progress-circular {
  margin-top: 1px !important;
}

.v-card__actions .v-progress-circular circle {
  color: white !important;
}
</style>