<template>
  <v-app>
    <div>

      <BreadcrumbComponent :items=items></BreadcrumbComponent>

      <PreloaderComponent :enable=enable></PreloaderComponent>

      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px" @click:outside="close">

          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ form_title }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row style="margin-top:4px">
                  <v-col cols="6" sm="6" md="6">
                    <input label="Nom *" required v-model=device.name v-on:keyup="validateName()" placeholder="Nom *"
                      class="form-control"
                      :style="validate_name ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_name" style="color:#ff7370;font-style:italic">
                      {{ validate_name_error_message }}
                    </small>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <select v-model=device.store_id class="form-control" label="Magasin *" placeholder="Magasin *" required>
                      <option value="" selected>-- Magasin --</option>
                      <option v-for="store in stores" v-bind:key="store.id" v-bind:value="store.id">{{ store.name }}
                      </option>
                    </select>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <input label="No de serie *" required v-model=device.serial_number v-on:keyup="validateSerialNumber()" placeholder="No de serie *"
                      class="form-control"
                      :style="validate_serial_number ? '' : 'border:2px solid #ff7370!important;box-shadow: none;'">
                    <small v-if="!validate_serial_number" style="color:#ff7370;font-style:italic">
                      {{ validate_serial_number_error_message}}
                    </small>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <select v-model=device.status class="form-control" label="Statut *" placeholder="Statut *" required>
                      <option :value="true">Actif</option>
                      <option :value="false">Inactif</option>
                    </select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" class="close_modal_button" text @click="close">
                Fermer
              </v-btn>
              <v-btn color="blue" text class="confirm_modal_button" v-on:click=save :loading="loading"
                :disabled="loading" @click="loader = 'loading'">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-container fluid class="settingscontainer" v-if=enable>
        <v-card elevation="2" class="text-right">

          <v-card>

            <v-card-title>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                class="tablesearch">
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="headers" :items="rows" :items-per-page="10" class="elevation-1" :search="search">
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-left">{{ item.serial_number }}</td>
                  <td class="text-left">{{ item.manufacturer }}</td>
                  <td class="text-left">{{ item.model }}</td>

                  <td class="text-left" v-if='item.status == 1'><span class="badge badge-success">Actif</span></td>
                  <td class="text-left" v-if='item.status == 0'><span class="badge badge-danger">Inactif</span></td>

                  <td class="text-right">
                    <v-icon small class="mr-2 edit_button" @click="edit(item)">
                      mdi-pencil
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          
        </v-card>
      </v-container>

    </div>
  </v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'DeviceView',
  methods: {
    load: async function () {
      this.business = JSON.parse(localStorage.business)
      this.authentication = JSON.parse(localStorage.authentication)
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      this.$axios.get('/devices?include=business,store&business_id=' + this.business_id)
        .then(function (response) {
          self.rows = response.data
          self.enable = true
        })
    },
    validateName: function () {
      if (this.device.name == '') {
        this.validate_name = false
        this.validate_name_error_message = "Le nom ne peut pas être vide."
        return false
      } else {
        this.validate_name = true
        return true
      }
    },
    validateSerialNumber: function () {
      if (this.device.serial_number == '') {
        this.validate_serial_number = false
        this.validate_serial_number_error_message = "Le numéro de série ne peut pas être vide."
        return false
      } else if (this.device.serial_number.length < 10) {
        this.validate_serial_number = false
        this.validate_serial_number_error_message = "Le numéro de série doit contenir au moins 10 caractères."
        return false
      } else {
        this.validate_serial_number = true
        return true
      }
    },
    getStatusColor: function (status) {
      var color = "class_red"
      if (status) {
        color = "class_green"
      }
      return color
    },
    getStores: function () {
      var self = this;
      this.$axios.get('/stores?business_id=' + this.business_id)
        .then(function (response) {
          self.stores = response.data
        })
    },
    save: function () {
      var self = this

      if (this.validateName() && this.validateSerialNumber() && this.device.store_id != '') {
        this.loading = true
        var url = ''
        if (this.index > -1) {
          url = '/devices/' + self.device.id
          this.$axios.put(url + "?include=business,store&data=" + encodeURIComponent(JSON.stringify(self.device)))
            .then(response => {
              Object.assign(self.rows[self.index], response.data)
              self.dialog = false
              self.loading = false
              self.reset()
            })
        } else {
          url = '/devices'
          this.device.business_id = this.business_id
          this.$axios.post(url + "?include=business,store&data=" + encodeURIComponent(JSON.stringify(self.device)))
            .then(response => {
              if (response.status < 200 || response.status > 299) {
                self.loading = false
                alert("Une erreur s'est produite. Veuillez réessayer plus tard ou contactez l'administrateur.")
                return
              }
              self.rows.push(response.data);
              self.dialog = false
              self.loading = false
              self.reset()
            }).catch(function (error) {
              self.loading = false
              alert("Une erreur s'est produite. Veuillez réessayer plus tard ou contactez l'administrateur.")
              console.log(error)
            })
        }
      } else {
        alert("Vérifiez les données du formulaire puis réessayez.")
      }
    },
    edit: function (item) {
      this.index = this.rows.indexOf(item)
      this.device = Object.assign({}, item)
      this.form_title = "Modifier l'Appareil : " + this.device.name
      this.dialog = true
    },
    close: function () {
      this.dialog = false
      this.reset()
      this.loading = false
    },
    reset: function () {
      this.device = {
        name: '',
        manufacturer: '',
        model: '',
        serial_number: '',
        store_id: "",
        amount: 0,
        status: true,
        next_renewal_date: (new Date(Date.now())).toISOString().substr(0, 10),
      }
      this.validate_name = true
      this.validate_serial_number = true
      this.index = -1
      this.form_title = 'Nouvel Appareil'
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.reset()
      })
    }
  },
  mounted() {
    this.load()
    this.getStores()
  },

  data: () => ({
    menu: false,
    modal: false,
    authentication: [],
    search: '',
    index: -1,
    onLoad: false,
    validate_name: true,
    validate_serial_number: true,
    validate_name_error_message: 'Le nom ne peut pas être vide.',
    validate_serial_number_error_message: 'Le numéro de série ne peut pas être vide.',
    loading: false,
    business_id: 0,
    form_title: "Nouvel Appareil",
    items: [
      {
        text: 'APPAREILS',
        disabled: false,
        href: '/point-of-sales',
      }
    ],
    fluid: true,
    enable: false,
    business: [],
    headers: [
      {
        text: 'NOM',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'SN', value: 'serial_number' },
      { text: 'FOURNISSEUR', value: 'manufacturer' },
      { text: 'MODELE', value: 'model' },
      { text: 'STATUT', value: 'status' },
      { text: 'ACTIONS', value: 'actions' },
    ],
    rows: [],
    businesses: [],
    stores: [],
    dialog: '',
    dialogDelete: false,
    device: {
      name: '',
      manufacturer: '',
      model: '',
      serial_number: '',
      amount: 0,
      status: true,
      store_id: "",
      next_renewal_date: (new Date(Date.now())).toISOString().substr(0, 10),
    }
  }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
.text-right {
  text-align: right;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #003F74 !important;
}

.v-application--wrap .theme--dark.v-btn.v-btn--has-bg {
  background-color: #F68D3A !important;
}

.settingscontainer {
  margin-top: 25px !important;
}

.v-application a {
  color: black !important;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

th.text-start,
td.text-start {
  text-align: center !important;
}

th.text-start:first-child,
td.text-start:first-child {
  text-align: left !important;
}

th.text-start:last-child,
td.text-start:last-child {
  text-align: right !important;
}

.class_green {
  background: #4caf50 !important;
  color: white !important;
}

.class_red,
.confirm_delete_button {
  background: #ff5252 !important;
  color: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.tablesearch .v-input__slot:before {
  display: none;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.edit_button {
  font-size: 16px;
  padding: 5px;
  background: #003F74 !important;
  color: white !important;
  border-radius: 4px;
}

.renew_button {
  font-size: 16px;
  padding: 5px;
  background: orange !important;
  color: white !important;
  border-radius: 4px;
}

.delete_button {
  background: #ff5252 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
}

.v-application .justify-center {
  justify-content: right !important;
}

.v-application .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 18px;
}

.v-application .v-dialog .v-btn {
  width: 70px !important;
  margin-right: 22px;
  margin-top: 0px !important;
}

.v-dialog .v-card__title {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
}

.v-btn.close_modal_button {
  background-color: #1976d2 !important;
  color: white !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
}

.v-dialog .v-card__actions {
  padding-bottom: 15px !important;
  padding-top: 20px !important;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
}

.new_button {
  margin-top: -7px !important;
}

.color-red {
  color: red !important;
}

.has_errors {
  padding-top: 0px;
  margin-top: 0px;
}

.v-card__actions .v-progress-circular {
  margin-top: 1px !important;
}

.v-progress-circular {
  margin-top: 40px !important;
}

.v-card__actions .v-progress-circular circle {
  color: white !important;
}

.background_red {
  background: #f2dede;
}

.background_yellow {
  background: #fcf8e3;
}
</style>